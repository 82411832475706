import React, {useCallback, useEffect, useRef, useState} from 'react'
import AgentChat from "./components/AgentChat";
import 'bootstrap/dist/css/bootstrap.min.css';
import DebugMenu from "./components/common/DebugMenu/DebugMenu";
import {
	AgentChatConfigStoragePath,
	getClientsList, loadAgentChatConfigParams, saveAgentChatConfigParams
} from "./configs/configHelper";
import {getDebugLoginUrl, getEnvVariable, isDebugEnabled, isNewConfig} from "./utils/helpers";

const App = () => {
	const [clientsConfig, setClientsConfig] = useState({});
	const [clientsEnv, setClientsEnv] = useState(null);
	const [clientId, setClientId] = useState(null);
	const [isShow, setIsShow] = useState(true);

	const agentChatRef = useRef();

	useEffect(() => {
		if (isNewConfig()) {
			getClientsList(getEnvVariable('amazon_clients_config_url')).then(clients => {
				setClientsConfig(clients);
			});
		} else {
			const cfg = loadAgentChatConfigParams();
			if (isDebugEnabled()) {
				setClientId(cfg.clientId);
			} else {
				setClientId("default");
			}
		}
	}, []);

	useEffect(() => {
		if (Object.keys(clientsConfig).length === 0) {
			return;
		}
		if (isDebugEnabled()) {
			const cfg = loadAgentChatConfigParams();
			setClientsEnv(cfg.env);
			setClientId(cfg.clientId);
		} else {
			setClientsEnv(clientsConfig['default']?.env || '');
			setClientId(clientsConfig['default']?.id || '');
		}
	}, [clientsConfig]);

	const ConfigSelectOptionsOld = () => {
		if (isDebugEnabled()) {
			return Object.keys(AgentChatConfigStoragePath).map((key, idx) => {
				return <option key={idx} value={key}>{AgentChatConfigStoragePath[key]?.title || ('Client Id: ' + key)}</option>;
			});
		} else {
			if (clientId !== "default") {
				saveAgentChatConfigParams('', '');
			}
			return <option value="default">{AgentChatConfigStoragePath["default"]?.title || 'Default'}</option>
		}
	};

	const ConfigPanelOld = () => {
		return <>
			Config:
			<select style={{width: 220, marginLeft: 5, marginRight: 30}}
					value={clientId || ''}
					onChange={(e) => {
						const value = e.target.value;
						if (!value) {
							agentChatRef?.current?.doTerminate();
						}
						// *** useEffect for saving clientId is bad idea (resets on reload) ***
						saveAgentChatConfigParams(clientsEnv, value);
						setClientId(value);
					}}>
				<option value=''>---</option>
				<ConfigSelectOptionsOld />
			</select>
		</>
	};

	const SelectEnvOptions = () => {
		return Object.keys(clientsConfig).map((key, idx) => {
			if (key.toLowerCase() === 'default') {
				return '';
			}
			return <option key={idx} value={key}>{key}</option>;
		});
	};

	const ConfigSelectOptions = () => {
		if (clientsEnv) {
			return clientsConfig[clientsEnv].clients.map(client => {
				return <option key={client.id} value={client.id}>{client.name || ('Client Id: ' + client.id)}</option>;
			});
		}
	};

	const ConfigPanel = () => {
		if (!isNewConfig()) {
			return <ConfigPanelOld />
		}
		return <>
			Environment:
			<select style={{width: 120, marginLeft: 5, marginRight: 10}}
				value={clientsEnv || ''}
				onChange={(e) => {
					const value = e.target.value;
					if (clientsEnv !== value) {
						agentChatRef?.current?.doTerminate();
					}
					// *** useEffect for saving clientId is bad idea (resets on reload) ***
					saveAgentChatConfigParams(value, '');
					setClientsEnv(value);
					setClientId('');
				}}>
				<option value=''>---</option>
				<SelectEnvOptions />
			</select>
			Client:
			<select style={{width: 220, marginLeft: 5, marginRight: 30}}
					value={clientId || ''}
					onChange={(e) => {
						const value = e.target.value;
						if (!value) {
							agentChatRef?.current?.doTerminate();
						}
						// *** useEffect for saving clientId is bad idea (resets on reload) ***
						//setAgentChatConfigName(value);
						saveAgentChatConfigParams(clientsEnv, value);
						setClientId(value);
					}}>
				<option value=''>---</option>
				<ConfigSelectOptions />
			</select>
		</>
	};

	// Test function
	const getLiveChatDataAsync= async (responseToken) => {
		return {
			"patientMrn":"12345",
			"patientFullName":"Nick FakeName (token: " + responseToken + ")",
			"referralTeam": "The referral team",
			"referralExtId":"123456879"
		};
	};

	const logoutUser = () => {
		const result = agentChatRef.current.doLogoutAgent()
		console.warn('check is can logout agent', result)
	}

	const handleToggleChatVisiblity = useCallback(() => {
		setIsShow(prev => !prev)
	}, [])

	const onUpdateContacts = useCallback((event) => {
		console.log('^updates contacts status', event)
	}, [])

	return (
		<div className="main">
			<div className="container-fluid">
				<div className="panel-dev">
					{
						isDebugEnabled() ?
							<DebugMenu
								buttons={[
									{title: 'Show Common Error', onClick: () => agentChatRef.current.doShowError()},
									{title: 'Show Network Error', onClick: () => agentChatRef.current.doShowNetworkError()},
									{title: 'Show Debug Info', onClick: () => agentChatRef.current.doShowDebugInfo()},
									{title: 'Download Log', onClick: () => window.connect.getLog().download()},
									{title: '---'},
									{title: 'Terminate Chat', onClick: () => window.confirm("Terminate chat?") && agentChatRef.current.doTerminate()},
									{title: 'Toggle chat visibility', onClick: handleToggleChatVisiblity},
									{title: 'Logout', onClick: logoutUser},
								]}
							/>
							:
							''
					}
				</div>

				<div className="additional-panel container"
					 style={{
						 textAlign: "right",
						 padding: "19px 30px 0 0",
						 position: "absolute",
						 right: "0px"
					 }}
				>
					{
						isDebugEnabled() ? <ConfigPanel /> : ''
					}
					<span
						onClick={() => agentChatRef.current ? agentChatRef.current.doReloadChat() : false}
						style={{fontSize: "12px", color: "#888", cursor: "pointer", zIndex: 1}}>
							<i className="fa fa-undo" /> Refresh page
						</span>
				</div>

				<div style={{textAlign: "center", margin: "0 0 15px 0"}}>
					{
						!isDebugEnabled() ?
							<img src="/images/default-logo.svg" style={{width: "270px"}}/>
							:
							<div style={{height: "70px"}} />
					}
				</div>
				<div className={`${isShow ? 'visible-test' : 'hide-test' }`} style={{height: "780px"}}>
					{
						clientId ?
							<AgentChat
								ref={agentChatRef}
								clientId={clientId}
								config={null}
								configApi={(isNewConfig()) ? clientsConfig[clientsEnv]?.endpoint : null}
								loginUrl={getEnvVariable('login_url')}
								debugLoginUrl={getDebugLoginUrl()}
								getLiveChatDataAsync={getLiveChatDataAsync}
								onUpdateContacts={onUpdateContacts}
							/>
							:
							<div style={{width: "100%", textAlign: "center"}}>Agent Chat config is incorrect or unavailable</div>
					}
				</div>
			</div>
		</div>
	);
};

export default App;
