import React from "react";

export const ImmunizationMessage = (props) =>
{
    const {data, messageTime} = props;
    const immunizationInfo = data?.additionalData?.immunization;

    return <>
        {
            immunizationInfo ?
                <div className={"row message-left"}>
                    <div className="col-md-9">
                        <p title={messageTime}>
                            New Immunization Record Request: <br/>
                            {
                                immunizationInfo.isChild ?
                                    <>
                                        For child.<br/>
                                        For school needs: {immunizationInfo.isForSchool ? 'yes' : 'no'}<br/>
                                        {
                                            immunizationInfo.isForSchool ?
                                                <>
                                                    Patient's school: {immunizationInfo.schoolPlace}<br/>
                                                </>
                                                :
                                                ''
                                        }
                                        Patient's provider: {immunizationInfo.patientProvider}<br/>
                                        Patient's Name and DOB: {immunizationInfo.nameAndDOB}<br/>
                                        Patient up to date on their Well Child
                                        Check: {immunizationInfo.isWellChildCheck ? 'yes' : 'no'}<br/>
                                        {
                                            immunizationInfo.isWellChildCheck ?
                                                <>
                                                    Receive by: {immunizationInfo.howReceiveRecords}
                                                </>
                                                :
                                                ''
                                        }
                                    </>
                                    :
                                    <>
                                        For adult.<br/>
                                        Patient's provider: {immunizationInfo.patientProvider}<br/>
                                        Patient's Name and DOB: {immunizationInfo.nameAndDOB}<br/>
                                        Receive by: {immunizationInfo.howReceiveRecords}
                                    </>
                            }
                        </p>
                        <span className={"message-time "}>
                            {messageTime}
                        </span>
                    </div>
                    <div className="col-md-3">
                    </div>
                </div>
            :
            ''
        }
    </>;
}
