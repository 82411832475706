import React from "react";

export const AgentTransferNoteMessage = (props) => {
    const {note, messageTime} = props;
    const {agentName, value} = note;

    return <>
        {
            value ?
                <div className={"row message-left"}>
                    <div className="col-md-9">
                        <p title={messageTime}>
                            <i>Notes from previous agent <b>{agentName}</b>:</i>
                            <br/><br/>
                            {value}
                        </p>
                        <span className={"message-time "}>
                            {messageTime}
                        </span>
                    </div>
                    <div className="col-md-3">
                    </div>
                </div>
                :
                ''
        }
    </>;
}
