import { useEffect, useState } from 'react';
import {
	getAgentChatConfigFromAPI,
	getAgentChatConfigFromStorage, getAgentChatToken,
	getProviderAuthTokenFromURL, setAgentChatToken
} from "../configs/configHelper";
import {AppState} from "../utils/constant";
import {getEnvVariable} from "../utils/helpers";
import {routes} from "../utils/routes";
import api from "../services/api";

export const useAgentChatConfiguration = (props, callbackAppState) => {
	const [agentChatConfiguration, setAgentChatConfiguration] = useState({});

	const {config, clientId, orgId, loginUrl, debugLoginUrl} = props;

	const updateLoginUrl = (_config) => {
		if (debugLoginUrl) {
			_config.loginUrl = insertInstanceIdToUrl(debugLoginUrl, _config.instanceId);
			return;
		}
		if (!loginUrl) {
			_config.loginUrl =`${_config.xApiUrl}${routes.AGENT_AUTH}?provider=auth0&instance_id=${_config.instanceId}`;
		} else {
			_config.loginUrl = insertInstanceIdToUrl(loginUrl, _config.instanceId);
		}
	}

	const finalizeAgentChatConfig = (_config) => {
		let result = _config || {};
		result.xApiUrl = getEnvVariable('amazon_api_url');
		result.xApiKey = getEnvVariable('amazon_api_key');
		updateLoginUrl(result);
		return result;
	}

	useEffect(() => {
		callbackAppState(AppState.RELOAD);
		const getCfg = () => {
			if (config && Object.keys(config)?.length > 0) {
				return config;
			}
			if (props.configApi) {
				return getAgentChatConfigFromAPI(props.configApi, clientId);
			} else {
				return getAgentChatConfigFromStorage(clientId ? clientId : orgId);
			}
		}
		let _config;
		getCfg()
			.then(result => {
				_config = finalizeAgentChatConfig(result);
				if (debugLoginUrl) {
					return null;
				}
				if (getAgentChatToken()) {
					return {token: getAgentChatToken()};
				}
				const selfUrl = new URL(window.location.href);
				const _loginUrl = new URL(_config.loginUrl);
				const providerToken = selfUrl.searchParams.get('provider_token') ?? getProviderAuthTokenFromURL(_config.loginUrl);
				if (!providerToken) {
					selfUrl.searchParams.set('old', _loginUrl.searchParams.get('old'));
					_loginUrl.searchParams.set('redirect_uri', encodeURIComponent(selfUrl.toString()));
					window.location.href = _loginUrl.toString();
					return null;
				}
				let provider = selfUrl.searchParams.get('provider');
				if (!provider) {
					provider = _loginUrl.searchParams.get('provider');
				}
				let tokenType = selfUrl.searchParams.get('token_type');
				if (!tokenType) {
					tokenType = _loginUrl.searchParams.get('token_type');
				}
				return api.getAgentJwtToken({
					provider: provider,
					providerToken: providerToken,
					tokenType: tokenType,
					isOld: selfUrl.searchParams.get('old') ?? _loginUrl.searchParams.get('old'),
				}, _config);
			})
			.then(result => {
				if (!result) {
					return;
				}
				if (!result?.token) {
					throw "Wrong authorization token";
				}
				setAgentChatToken(result.token);
				_config.xApiToken = result.token;
				_config.loginUrl = `${_config.xApiUrl}${routes.AGENT_AUTH}?token=${result.token}&instance_id=${_config.instanceId}`;
				return api.validateAgentRoutingProfile(_config);
			})
			.then(() => {
				setAgentChatConfiguration(_config);
			})
			.catch(() => {
				callbackAppState(AppState.ERROR);
			});
	}, [clientId, orgId]);

	const insertInstanceIdToUrl = (url, instanceId) => {
		const parsedUrl = new URL(url);
		const redirectUri = parsedUrl.searchParams.get('redirect_uri');
		if (redirectUri) {
			const redirectUrl = new URL(redirectUri);
			redirectUrl.searchParams.set('instance_id', instanceId);
			parsedUrl.searchParams.set('redirect_uri', redirectUrl.toString());
		} else if (parsedUrl.searchParams.get('instance_id')) {
			parsedUrl.searchParams.set('instance_id', instanceId);
		} else {
			parsedUrl.searchParams.append('instance_id', instanceId);
		}

		return parsedUrl.toString();
	};

	return agentChatConfiguration;
}
