import axios from 'axios';
import { routes } from '../utils/routes';

const sendChatCategory = (chatCategory,freetext, config) => {
	return axios.post(`${config.xApiUrl}${routes.AGENT_FEEDBACK}`, chatCategory, {
		headers: {
			'x-api-key': config?.xApiKey,
			'Content-Type': 'application/json',
		},
	}).then(response => response);
};

const sendTransferNote = (config,data) => {
	return axios.post(`${config.xApiUrl}${routes.AGENT_NOTE}`, data, {
		headers: {
			'x-api-key': config?.xApiKey,
			'Content-Type': 'application/json',
		},
	}).then(response => response);
};

const getContactTranscript = (config) => {
	const url = `${config.xApiUrl}${routes.CONTACTS_TRANSCRIPT}/${config.contactId}` +
		`?source=raw` +
		`&clientApiKey=${config?.clientApiKey || ''}` +
		`&getPrevious=${config?.getPrevious || 0}`;
	return axios.get(url,{
		headers: {
			'x-api-key': config?.xApiKey,
			'Content-Type': 'application/json',
		},
	}).then(response => {
		return response?.data?.data;
	});
};

const getContactData = (params, config) => {
	const {cmd, apiKey, sessionId} = params;
	const url = `${config.xApiUrl}${routes.CONTACT_DATA}` +
		`?cmd=${cmd}` +
		`&apiKey=${apiKey}` +
		`&sessionId=${sessionId}`;
	return axios.get(url,{
		headers: {
			'x-api-key': config?.xApiKey,
			'Content-Type': 'application/json',
		},
	}).then(response => {
		return response?.data;
	});
};

const sendTemplateUsageStatistic = (params, config) => {
	const {contactId, agentUsername, templateId, isModified} = params;
	if (!config?.instanceId || !contactId || !templateId) {
		return new Promise(resolve => resolve(null));
	}
	const postData = {
		contactId: contactId,
		agentUsername: agentUsername,
		instanceId: config.instanceId,
		templateId: templateId,
		isModified: isModified
	};
	return axios.post(`${config.xApiUrl}${routes.TEMPLATE_USAGE_STATISTIC}`, postData, {
		headers: {
			'x-api-key': config?.xApiKey,
			'Content-Type': 'application/json',
		},
	}).then(response => response);
};

const sendEndSessionBySessionId = (data, {url = 'https://va-uat-api.odeza.com/v1', token}) => {
	return axios.post(`${url}${routes.END_SESSION}`, data, {
		headers: { 'Authorization': token },
	})
};

const getAgentJwtToken = (params, config) => {
	const {provider, providerToken, tokenType, isOld} = params;
	const url = `${config.xApiUrl}${routes.AGENT_AUTH}` +
		`?provider=${provider}` +
		`&provider_token=${providerToken}` +
		(tokenType ? `&token_type=${tokenType}` : '') +
		`&t=true` +
		(isOld ? `&old=${isOld}` : '');
	return axios.get(url,{
		headers: {
			'x-api-key': config?.xApiKey,
			'Content-Type': 'application/json',
		},
	}).then(response => {
		return response?.data;
	});
}

const validateAgentRoutingProfile = (config) => {
	if (!config?.xApiToken) {
		return new Promise(resolve => resolve(null));
	}
	const url = `${config.xApiUrl}${routes.AGENT_AUTH}?cmd=validate-routing-profile`;
	return axios.get(url,{
		headers: {
			'Authorization': `Bearer ${config?.xApiToken}`,
			'Content-Type': 'application/json',
		},
	}).then(response => response);
}

export default {
	sendChatCategory,
	getContactTranscript,
	getContactData,
	sendTemplateUsageStatistic,
	sendTransferNote,
	sendEndSessionBySessionId,
	getAgentJwtToken,
	validateAgentRoutingProfile,
};
