import React, {useEffect, useMemo, useState} from "react";
import {ThreeDots as LoaderThreeDots} from "react-loader-spinner";
import {scrollToBottom} from '../../utils/helpers.js'

export const CustomerAttributesMessage = (props) => {
    const {getContactDataAttributes, messageTime} = props;

    const [contactMetadata, setContactMetadata] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [hideInfo, setHideInfo] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        getContactDataAttributes()
            .then(data => {
                setContactMetadata(data || {});
                scrollToBottom()
                /* // Disabled
                if (!data?.productName
                    && !data?.attributes?.patientName
                    && !data?.attributes?.mrn
                ) {
                    setHideInfo(true);
                } else {
                    setHideInfo(false);
                }
                */
            })
            .finally(() => setIsLoading(false));
    }, []);

    const BaseInfo = () => {
        return <>
            {contactMetadata?.productName && <div>Product Name: {contactMetadata?.productName}</div>}
            {contactMetadata?.attributes?.patientName && <div>Patient Name: {contactMetadata?.attributes?.patientName}</div>}
            {contactMetadata?.attributes?.mrn && <div>Medical Record Number: {contactMetadata?.attributes?.mrn}</div>}
            {contactMetadata?.attributes?.emailAddress && <div>Email Address: {contactMetadata?.attributes?.emailAddress}</div>}
            {contactMetadata?.attributes?.phoneNumber && <div>Phone Number: {contactMetadata?.attributes?.phoneNumber}</div>}
        </>
    }

    const MessageContext = () => {
        return contactMetadata?.messageContext?.map((items, idx) => {
            return items?.value ? <div key={idx}>{items?.label}: {items?.value}</div> : '';
        });
    }

    return <>
        {
            <div className={"row message-left " + (hideInfo ? 'd-none' : '')}>
                <div className="col-md-9">
                    <div className="message-left-body" title={messageTime}>
                        Customer info<hr style={{margin: "7px 0"}} />
                        {
                            isLoading ?
                                <LoaderThreeDots
                                    color="#d7d7d7"
                                    height={30}
                                    width={30}
                                    timeout={0}
                                />
                                :
                                <>
                                    <BaseInfo />
                                    <MessageContext />
                                </>
                        }
                    </div>
                    <span className={"message-time "}>
                        {messageTime}
                    </span>
                </div>
                <div className="col-md-3">
                </div>
            </div>
        }
    </>;
}
